// extracted by mini-css-extract-plugin
export var root = "PlasmicPricing-module--root--smS4M";
export var header = "PlasmicPricing-module--header--Hscvw";
export var pricingSection = "PlasmicPricing-module--pricingSection--7ti0p";
export var columns = "PlasmicPricing-module--columns--kLTUR";
export var column___9RNvH = "PlasmicPricing-module--column___9RNvH--MCVgw";
export var plan__tPlE5 = "PlasmicPricing-module--plan__tPlE5--VX5x6";
export var text___1J8Ck = "PlasmicPricing-module--text___1J8Ck--fmtsS";
export var freeBox__yjAf = "PlasmicPricing-module--freeBox__yjAf--glyZS";
export var bullet__d3Sd = "PlasmicPricing-module--bullet__d3Sd--hULHb";
export var bullet__hbZ2R = "PlasmicPricing-module--bullet__hbZ2R--JqJ9R";
export var bullet__ctA2J = "PlasmicPricing-module--bullet__ctA2J--GzCon";
export var button__rNBnU = "PlasmicPricing-module--button__rNBnU--2dwRw";
export var svg___1Pvg0 = "PlasmicPricing-module--svg___1Pvg0--S31SY";
export var svg__qrgPn = "PlasmicPricing-module--svg__qrgPn--E0N4B";
export var column__ar8Po = "PlasmicPricing-module--column__ar8Po--sorK-";
export var plan__vPzTy = "PlasmicPricing-module--plan__vPzTy--G+wFn";
export var text__hsS0G = "PlasmicPricing-module--text__hsS0G--u7O0s";
export var freeBox__eHN4 = "PlasmicPricing-module--freeBox__eHN4--KdJ+i";
export var bullet___3Vgg = "PlasmicPricing-module--bullet___3Vgg--ZP+73";
export var bullet__a64D4 = "PlasmicPricing-module--bullet__a64D4--YHeix";
export var bullet___2AMz = "PlasmicPricing-module--bullet___2AMz--NRfH6";
export var bullet___3GsjW = "PlasmicPricing-module--bullet___3GsjW--RIh8X";
export var button__lAuYj = "PlasmicPricing-module--button__lAuYj--GwawI";
export var svg__qR8H = "PlasmicPricing-module--svg__qR8H--36CQR";
export var svg__pcwn2 = "PlasmicPricing-module--svg__pcwn2--9bQ55";
export var column__rjiPo = "PlasmicPricing-module--column__rjiPo--H72pj";
export var plan__ny22X = "PlasmicPricing-module--plan__ny22X--keMyE";
export var text__prFdn = "PlasmicPricing-module--text__prFdn--+NNvC";
export var freeBox__nbIcE = "PlasmicPricing-module--freeBox__nbIcE--6UecC";
export var bullet__eXo = "PlasmicPricing-module--bullet__eXo--G9ARB";
export var bullet__gd9Cn = "PlasmicPricing-module--bullet__gd9Cn--+rPsE";
export var bullet__dxz7 = "PlasmicPricing-module--bullet__dxz7--umMZF";
export var button__eWntu = "PlasmicPricing-module--button__eWntu--a03kv";
export var svg__a1Url = "PlasmicPricing-module--svg__a1Url--98Owt";
export var svg__fom0T = "PlasmicPricing-module--svg__fom0T--wtJ6I";
export var faqSection = "PlasmicPricing-module--faqSection--EDU6-";
export var freeBox___4KONs = "PlasmicPricing-module--freeBox___4KONs--8cadn";
export var faq__xb3Yf = "PlasmicPricing-module--faq__xb3Yf--iAKLz";
export var faq__eC0NU = "PlasmicPricing-module--faq__eC0NU--AW45t";
export var footer = "PlasmicPricing-module--footer--NprF5";