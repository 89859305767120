// extracted by mini-css-extract-plugin
export var root = "PlasmicPlan-module--root--6nN0K";
export var freeBox__ky6Jx = "PlasmicPlan-module--freeBox__ky6Jx--o8Zav";
export var freeBox__hpfkq = "PlasmicPlan-module--freeBox__hpfkq--VBEH9";
export var slotTargetName = "PlasmicPlan-module--slotTargetName---aCFz";
export var text__klMm = "PlasmicPlan-module--text__klMm--ZCiEm";
export var freeBox__kFSuR = "PlasmicPlan-module--freeBox__kFSuR--JdV-B";
export var bullet__a7O9I = "PlasmicPlan-module--bullet__a7O9I--1hN7v";
export var bullet__aPcZk = "PlasmicPlan-module--bullet__aPcZk--jgIzk";
export var bullet__k1Db7 = "PlasmicPlan-module--bullet__k1Db7--baNg6";
export var freeBox___23XwS = "PlasmicPlan-module--freeBox___23XwS--gEwhK";
export var button__kafuX = "PlasmicPlan-module--button__kafuX--a7rNF";
export var svg__qwBDd = "PlasmicPlan-module--svg__qwBDd--fzpe7";
export var svg__qaEl4 = "PlasmicPlan-module--svg__qaEl4--Lnc9f";
export var svg__wIfuf = "PlasmicPlan-module--svg__wIfuf--LRGBD";